// Avoid `console` errors in browsers that lack a console.
(function () {
  var method;
  var noop = function () { };
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

// (function ($) { 
//   window.fnames = new Array(); 
//   window.ftypes = new Array();
//    fnames[0] = 'EMAIL'; 
//    ftypes[0] = 'email'; 
//    fnames[1] = 'FNAME'; 
//    ftypes[1] = 'text'; 
//    fnames[2] = 'LNAME'; 
//    ftypes[2] = 'text';
//   }(jQuery)); 
//   var $mcj = jQuery.noConflict(true);

// Place any jQuery/helper plugins in here.
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/carousel';

